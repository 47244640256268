import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import IconBowling3d from "../components/images/icons/image_icon_bowling3d"
import IconCalc from "../components/images/icons/image_icon_calc"
import IconPf from "../components/images/icons/image_icon_pf"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="pb-3">
      <h2>Games</h2>
      <div className="d-flex">
        <Link to="/games/bowling3d" className="px-1">
          <IconBowling3d className="app-icon small radius" />
        </Link>
      </div>
    </section>
    <section className="pb-3">
      <h2>Tools</h2>
      <div className="d-flex">
        <Link to="/tools/calc-plus" className="px-1">
          <IconCalc className="app-icon small" />
        </Link>
        <Link to="/tools/prime-factorization" className="px-1">
          <IconPf className="app-icon small" />
        </Link>
      </div>
    </section>
  </Layout>
)

export default IndexPage
